import axios from "axios";

let axiosSetup = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosSetup.interceptors.request.use((config) => {
  let guid = localStorage.getItem("guid");
  if (guid !== null) {
    config.params = { ...config.params, guid: guid };
  }
  return config;
});

var createApiRoute = (route, data) => {
  let apiRoute = API.routes[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      apiRoute = apiRoute.replace(`{${key}}`, data[key]);
    }
  }

  return apiRoute;
};

var createRoute = (route, data) => {
  let apiRoute = API.appRoutes[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      apiRoute = apiRoute.replace(`{${key}}`, data[key]);
    }
  }

  return apiRoute;
};

//create url from parts, stripping the necessary slashes at the end of each part
var createRouteFromParts = (...parts) => {
  parts = parts.map((part) => {
    return part && part.replace(/^\/+|\/+$/g, "");
  });

  let url = parts.join("/");

  return url;
};

var createExternalLink = (link) => {
  return API.externalLinks[link];
};

// axiosSetup.defaults.withCredentials = true;

export const API = {
  createApiRoute: createApiRoute,
  createRoute: createRoute,
  createRouteFromParts: createRouteFromParts,
  createExternalLink: createExternalLink,
  axios: axiosSetup,
  routes: {
    academies: "academies/",
    user: "user/",
    questions: "questions/",
    startMemoryMatrix: "memory-matrix/start",
    resultMemoryMatrix: "memory-matrix/result",
    startSpeedArithmetic: "speed-arithmetic/start",
    resultSpeedArithmetic: "speed-arithmetic/result",
    assessment: "assessment/",
    result: "result/?guid={GUID}",
    term: "term/",
    legal: "legal/",
    resultsByAcademy: "results/academy/{ACADEMY_ID}",
    resultsByToken: "results/token/{TOKEN}",
    registration: "user/complete",
    landingNumbers: "landing/numbers",
    timeout: "/timeout",
  },
  appRoutes: {
    home: "/",
    // apply: "/assessment/apply/{ACADEMY_ID}",
    apply: "/apply/{ACADEMY_ID}",
    // applyWithReferral: "/assessment/apply/reffer/{REFERRAL_GUID}/{ACADEMY_ID}",
    applyWithReferral: "/apply/reffer/{REFERRAL_GUID}/{ACADEMY_ID}",
    confirm: "/assessment/confirm/{ACADEMY_ID}",
    assessment: "/assessment",
    ongoing: "/assessment/ongoing",
    ongoingMemoryGame: "/memory-game/ongoing",
    terms: "/terms_and_conditions",
    result: "/result/{GUID}",
    rules: "/rules/",
    awards: "/leaderboard/{ACADEMY_ID}",
    awardsWithToken: "/leaderboard/{ACADEMY_ID}/{TOKEN}",
    awardsPending: "/pending",
    registration: "/registration",
    applicationClosed: "/application-closed",
    memoryGame: "/memory-game",
    memoryGameResult: "/memory-game/result",
    speedArithmeticGame: "/speed-arithmetic-game",
    liveResults: "/live-results",
    stressometer: "/stressometer",
  },
  externalLinks: {
    softwareCollege: "https://next.edu.mk/softversko-inzenerstvo-i-inovacii/",
    graphicDesign: "https://next.edu.mk/graficki-dizajn/",
    brainsterNextLinkedIn: "https://www.linkedin.com/company/brainsternext/",
    brainsterNextFacebook: "https://www.facebook.com/brainsternext",
    brainsterNextInstagram: "https://www.instagram.com/brainsternext/",
    nextPlatform: "https://platform.next.edu.mk",
    nextMedia: "https://media.next.edu.mk/",
  },
  storage: process.env.REACT_APP_STORAGE_URL,
};
