import React from "react";
import styled from "styled-components/macro";

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  z-index: 1000;

  span {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--color);
    animation: fall 2s linear forwards;
    opacity: 0;
    transform: translateY(-100vh);
  }

  @keyframes fall {
    0% {
      opacity: 1;
      transform: translateY(-10%);
    }
    100% {
      opacity: 0;
      transform: translateY(100vh);
    }
  }

  @media (max-width: 768px) {
    span {
      width: 5px;
    }
  }
`;

const Confetti = () => {
  return (
    <StyledContainer className="confetti">
      {Array.from({ length: 50 }).map((_, index) => (
        <span
          key={index}
          style={{
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 0.5}s`,
            "--color": `hsl(${Math.random() * 260}, 100%, 50%)`,
          }}
        />
      ))}
    </StyledContainer>
  );
};

export default Confetti;
