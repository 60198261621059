import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ekgLine from "./Static/ekg-line.svg";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  background: #1a1b26;
  overflow:hidden ;
  position: relative;

  .ekg-line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    width: 100%;
    height: 200px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledInnerContainer = styled.div`
  color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(255, 255, 255, 0.38);

  .stress-meter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto;
    position: relative;
  }

  .stress-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    text-align: right;
    padding-right: 0.5rem;
    font-size: 0.8rem;
    color: white;
  }

  .stress-meter {
    width: 60px;
    height: 300px;
    background: #eee;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    border: 2px solid #ddd;
  }

  .stress-value {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -2rem;
    font-weight: bold;
    color: white;
    padding-left: 55px;
  }

  input[type="number"] {
    width: 100%;
    padding: 0.8rem;
    margin: 15px 0 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    box-sizing: border-box;
    background-color: transparent;
    color: white;
    font-size: 1rem;
    outline: none;
  }

  .slider-container {
    position: relative;
    margin: 2rem 0;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: linear-gradient(to right, green, yellow, orange, red);
    outline: none;
    margin: 1rem 0;
    position: relative;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  }

  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  }

  .slider-value {
    text-align: center;
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
  }
  .slider-container .slider-levels {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #666;
    position: absolute;
    width: 100%;
    top: 55px;
    left: 0;
  }

  .slider-levels span {
    text-align: center;
  }

  button {
    width: 100%;
    padding: 1rem;
    background: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

  button:hover {
    background: #45a049;
  }

  .error {
    color: #ff4b4b;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: white;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const StressLevel = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, #ff4b4b, #ff8f8f);
  transition: height 0.5s ease;
  border-radius: 30px;
  animation: pulsate 2s ease-in-out infinite;

  @keyframes pulsate {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbzUlYsJKB19CV3ztL-j6BzzkBZP2jlE1a_hgh65zACDSrc7DYm77fjI9oklTtZ8bD6s4w/exec";
const Stressometer = () => {
  const [participantId, setParticipantId] = useState("");
  const [stressLevel, setStressLevel] = useState(50);
  const [averageStress, setAverageStress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    initStressMeter();
  }, []);

  async function initStressMeter() {
    try {
      const response = await fetch(`${SCRIPT_URL}?action=getCurrentStress`);
      const data = await response.json();
      if (data.success) {
        setAverageStress(data.averageStress);
      }
    } catch (error) {
      console.error("Error initializing stress meter:", error);
    }
  }

  const submitStressForm = async (e) => {
    e.preventDefault();
    let errorMessage;
    setIsLoading(true);

    if (participantId < 1 || participantId > 350) {
      errorMessage = "Participant ID must be between 1 and 350";

      return;
    }

    try {
      const response = await fetch(SCRIPT_URL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          participantId,
          stressLevel,
          action: "submitStress",
        }),
      });


      setTimeout(async () => {
        const updateResponse = await fetch(
          `${SCRIPT_URL}?action=getCurrentStress`
        );
        const data = await updateResponse.json();
        if (data.success) {
          setParticipantId("");
          setStressLevel("");
          setAverageStress(data.averageStress);
          setIsLoading(false);
        }
      }, 1000);
    } catch (error) {
      console.error("Error submitting stress level:", error);
      errorMessage = "Error submitting data. Please try again.";
      setIsLoading(false);
    }

    setError(errorMessage);
  };

  return (
    <StyledContainer>
      <div className="ekg-line-container">
        <img src={ekgLine} alt="" />
        <img src={ekgLine} alt="" />
        <img src={ekgLine} alt="" />
        <img src={ekgLine} alt="" />

      </div>

      <StyledInnerContainer>
        <Title>Stressometer</Title>

        <div className="stress-meter-container">
          <div className="stress-labels">
            <div>100</div>
            <div>80</div>
            <div>60</div>
            <div>40</div>
            <div>20</div>
            <div>0</div>
          </div>
          <div className="stress-meter">
            <StressLevel
              className="stress-level"
              id="stressBar"
              style={{ height: `${averageStress}%` }}
            ></StressLevel>
          </div>
          <div className="stress-value" id="stressValue">
            {Math.round(averageStress)}%
          </div>
        </div>

        <form id="stressForm" onSubmit={submitStressForm}>
          <input
            type="number"
            id="participantId"
            placeholder="Enter your ID (1-350)"
            min="1"
            max="350"
            required
            value={participantId}
            onChange={(e) => setParticipantId(e.target.value)}
          />

          <div className="slider-container">
            <label for="stressLevel">Stress Level</label>
            <input
              type="range"
              id="stressLevel"
              className="slider"
              min="0"
              max="100"
              required
              value={stressLevel}
              onChange={(e) => setStressLevel(e.target.value)}
            />
            <div className="slider-levels">
              <span>😌</span>
              <span>🤔</span>
              <span>😟</span>
              <span>😰</span>
              <span>😱</span>
            </div>
          </div>

          <button type="submit">{isLoading ? <div className="spinner"></div> : "Submit"}</button>
          <div id="error" className="error"></div>
        </form>
        <p className="error">{error}</p>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default Stressometer;
