import React from "react";
import styled from "styled-components";
import ExplodingConfetti from "./ExplodingConfetti";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  margin: 0;
  overflow: hidden;
  font-family: "Arial", sans-serif;
  height: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .star {
    position: absolute;
    animation: spin 3s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    padding-top: 250px;
  }
`;

const Trophy = styled.div`
  width: 200px;
  height: 200px;

  background-color: ${theme.neonYellow};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.5));
  animation: float 3s ease-in-out infinite;
  @keyframes float {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    width: 270px;
    height: 270px;
  }
`;
const InnerWinnerTrophy = styled.div`
  width: 180px;
  height: 180px;
  background-color: ${theme.forestGreen};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    width: 250px;
    height: 250px;
    font-size: 5rem;
  }
`;

const Title = styled.h1`
  font-size: 4em;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
  animation: pulse 2s ease-in-out infinite;
  text-transform: uppercase;

  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }

  @media (max-width: 992px) {
    /* Styles for Quad HD screens */
    font-size: 30px;
    text-align: center;
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    font-size: 100px;
  }
`;

const Subtitle = styled.h2`
  font-size: 2em;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  margin: 10px 0;

  @media (max-width: 992px) {
    /* Styles for Quad HD screens */
    font-size: 22px;
    text-align: center;
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    font-size: 3em;
  }
`;

const MathSymbols = styled.div`
  font-size: 2em;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 10px 0;

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    font-size: 3em;
  }
`;
const Formula = styled.div`
  background: rgba(255, 255, 255, 0.2);
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.5em;
  color: white;
  margin: 10px 0;
  font-family: "Times New Roman", serif;
  animation: glow 2s ease-in-out infinite;

  @keyframes glow {
    0%,
    100% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    }
    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
    }
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    font-size: 3em;
  }
`;

const symbols = ["∑", "√", "π", "∞", "∫", "+", "−", "×", "÷", "="];
const colors = ["#FFD700", "#FFA500", "#FF4500", "#FF6347"];
const TOTAL_STARS = 10;

const WinnerCelebration = ({ winnerNumber }) => {
  return (
    <StyledContainer>
      {Array.from({ length: TOTAL_STARS }).map((_, index) => (
        <div
          key={index}
          className="star"
          style={{
            left: Math.random() * 100 + "vw",
            top: Math.random() * 100 + "vh",
            fontSize: Math.random() * 20 + 10 + "px",
            color: colors[Math.floor(Math.random() * colors.length)],
          }}
        >
          {symbols[Math.floor(Math.random() * symbols.length)]}
        </div>
      ))}
      <Trophy>
        <InnerWinnerTrophy>{winnerNumber}</InnerWinnerTrophy>
      </Trophy>
      <Title>🥇 Ultimath champion 🥇</Title>
      <Subtitle>🏆 ЧЕСТИТКИ ЗА ОСВОЕНО ПРВО МЕСТО! 🏆</Subtitle>
      <ExplodingConfetti />
      <MathSymbols>∑ √ π ∞ ∫</MathSymbols>
      <Formula>y = mx + b</Formula>
      <Formula>(x - h)² + (y - k)² = r²</Formula>
      <ExplodingConfetti />
    </StyledContainer>
  );
};

export default WinnerCelebration;
