import React from "react";
import styled from "styled-components/macro";

const StyledContainer = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;

  opacity: 0;
  transform: scale(0.9);
  animation: fadeInScaleUp 0.6s ease-out forwards;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(255, 107, 107, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

  p.info-text {
    margin-bottom: 0;
    color: #ff6b6b; /* Soft red */
  }

  /* Fade-in and scale-up animation */
  @keyframes fadeInScaleUp {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* Encouraging message with soft pulse */
  .encouraging-text {
    display: inline-block;
    color: #ff9800;
    animation: softPulse 2s ease-in-out infinite;
  }

  /* Soft pulse animation */
  @keyframes softPulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 0.9;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.3;
  }
`;

const RejectMessage = ({ captionOne, captionTwo }) => {
  return (
    <StyledContainer>
      <p className="info-text">{captionOne}</p> <br />
      <p className="encouraging-text">{captionTwo}</p>
    </StyledContainer>
  );
};

export default RejectMessage;
