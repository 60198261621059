import React from "react";
import styled from "styled-components/macro";

import brainsterNextCover from "./Static/brainster-next-cover.jpg";
import ultimathCover from "./Static/ultimath-cover.jpg";
import BrainsterNextLogo from "../../Pages/Result/Blocks/BrainsterNextLogo";
import NUCollegeLogo from "../../Pages/Result/Blocks/NUCollegeLogo";

const StyledContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;


  .ultimath-cover-wrap,
  .brainster-next-cover-wrap {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.2);
    }
  }
   .ultimath-cover-wrap .overlay,
   .brainster-next-cover-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
    padding-left: 32px;
    padding-bottom: 32px;
    z-index: 1; /* Ensures the overlay stays on top of the image */
  }
   .ultimath-cover-wrap .overlay {
    background: radial-gradient(
      232.98% 151.16% at 64.3% -37.28%,
      rgba(205, 245, 69, 0) 0%,
      #cdf545 80.84%
    );
  }
   .brainster-next-cover-wrap .overlay {
    background: radial-gradient(
      232.98% 151.16% at 64.3% -37.28%,
      rgba(116, 151, 255, 0) 0%,
      #7497ff 80.84%
    );
  }
   .brainster-next-cover-wrap .overlay p.title,
   .ultimath-cover-wrap .overlay p.title {
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
  }
   .brainster-next-cover-wrap .overlay p.title {
    color: #fff;
  }
   .ultimath-cover-wrap .overlay p.title {
    color: #024f40;
  }

  @media (max-width: 768px) {
    width: 86%;
    margin: 0 auto;

    flex-direction: column;

  }
`;

export const UltimathBlogs = () => {
  return (
    <StyledContainer >
      <div className="brainster-next-cover-wrap">
        <a href="https://next.edu.mk/" target="_blank">
          <div className="overlay">
            <div className="brainster-next-logo-container">
              <BrainsterNextLogo />
              <p className="title">
                Aкредитиран практичен <br />
                IT факултет
              </p>
            </div>
          </div>
        </a>
        <img src={brainsterNextCover} alt="" />
      </div>
      <div className="ultimath-cover-wrap">
        <a href="https://nucollege.ai/" target="_blank">
          <div className="overlay">
            <div className="brainster-next-logo-container">
              <NUCollegeLogo />
              <p className="title">
                Прв кoлеџ за <br />
                средношколци
              </p>
            </div>
          </div>
        </a>
        <img src={ultimathCover} alt="" />
      </div>
    </StyledContainer>
  );
};
