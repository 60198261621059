import { useEffect, useState, useContext, useRef, useCallback } from "react";

//npm imports
import { keyframes } from "styled-components";
import styled, { withTheme } from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//component imports
import Wrapper from "../../Components/Wrapper/Wrapper";
import {
  Caption12,
  Header25,
  Text12,
  Text14,
  Text16,
  Text33,
} from "../../Components/Text/Text";
import { StyledButtonWide } from "../../Components/ButtonWide/ButtonWide";
import SelectInput from "../../Components/SelectInput/SelectInput";

//other imports
import { API } from "../../Consts/Api";
import useMediaCustom from "../../Hooks/useMediaCustom";
import { GlobalContext } from "../../Context/GlobalContext";
import { UltimathBlogs } from "../../Components/UltimathBlogs/UltimathBlogs";
import theme from "../../Consts/Theme";
import heroBackground from "./Static/heroBackground.png";
import heroBackgroundMobile from "./Static/bg-mobile.png";
import searchIcon from "./Static/search-icon.svg";
import Confetti from "../../Components/Confetti/Confetti";
import UltimathLogo from "../../Components/UltimathLogo/UltimathLogo";
import Footer from "../Homepage/Blocks/Footer/Footer";
import StructureCompetitionSection from "../Homepage/Blocks/StructureCompetitionSection/StructureCompetitionSection";
import ApproveMessage from "../../Components/Message/ApproveMessage";
import RejectMessage from "../../Components/Message/RejectMessage";

const spin = keyframes`
    100%{
        transform: rotate(-1turn);
    }
`;
const MainWrapper = styled.div`
  position: relative;
`;
const StyledHeader = styled.header`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 25px;

  p {
    width: 100% !important;
    text-align: center;
    margin-bottom: 8px;


  }
`;

//styled-components
const StyledAwardsMain = styled.main`
  width: 100%;
  background-image: url(${window.innerWidth > 768
    ? heroBackground
    : heroBackgroundMobile});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-bottom: 200px;
  h4,
  h5,
  p {
    color: ${(props) => props.theme.white};
  }

  div.logo-wrap {
    display: flex;
    justify-content: center;
  }

  div.logo-container {
    background-color: ${(props) => props.theme.white};
    padding: 16px 24px;
    border-radius: 16px;
    display: inline-flex;
  }

  & > div {
    max-width: 1224px;
    padding-top: 57px;
    & > header {
      margin-top: 42px;
      text-align: center;
      ${Text33} {
        margin-bottom: 18px;
      }
      ${Text16} {
        width: 65%;
      }
    }
    & > div.check-rank-container {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 150px;
  }

  @media (max-width: 550px) {
    & > div {
      & > header {
        padding-left: 0;
      }
      & > section.awards-table-container {
        & > div.awards-academy-dropdown-container {
          .select-input__value-container {
            padding: 0;
          }
        }
        & > div.awards-results-container {
          ul {
            padding: 0;
            li {
              padding: 12px 8px 12px 4px;
              p {
                .add-padding {
                  padding-left: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 425px) {
    & > div {
      & > header {
        ${Text33} {
          font-size: 25px;
          line-height: 30px;
          font-weight: 600;
        }
        ${Text16} {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          width: 100%;
        }
      }
      & > section.awards-table-container {
        & > div.awards-tabs-container {
        }
        & > div.awards-academy-dropdown-container {
          ${Text12} {
            padding-left: 0;
          }
          .select-input__value-container {
            padding: 0;
          }
          .select-input__control {
            .select-input__menu {
            }
          }
        }
        & > div.awards-results-container {
          ul {
            padding: 0;
            li {
              p {
                .token {
                  flex-basis: 23%;
                }
                .score {
                  flex-basis: 19%;
                }
                .time {
                  flex-basis: 8%;
                }
                .award {
                  flex-basis: 20%;
                }
              }
              ${Text12} {
              }
              ${Text16} {
              }
            }
          }
        }
      }
    }
  }
`;

const AcademyTab = styled.button`
  padding: 16px 8px;
  background-color: ${(props) =>
    props.highlight ? `rgb(255, 179, 211)` : props.theme.white};
  p {
    text-align: left;
    color: ${(props) =>
      props.highlight ? "rgb(128, 0, 59)" : props.theme.darkGray};
    text-align: center;
    font-size: 16px;
  }
  border: none;
  outline: none;
  box-shadow: none;
  &:focus,
  &:active,
  &:visited {
    border: none;
    outline: none;
    box-shadow: none;
  }
  cursor: pointer;
  ${Caption12} {
    font-size: 12px;
  }
`;

const StyledAwardsSearchSection = styled.section`
  width: 100%;

  & > .check-rank-container {
    width: 80%;
    margin: 0 auto;
    max-width: 900px;

    ${Caption12} {
      margin-top: 8px;
    }
  }
  @media (max-width: 768px) {
    & > .check-rank-container {
      width: 100%;
    }
  }
`;
const SearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  position: relative;

  img.search-icon {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    font-size: 16px;
  }

  input {
    flex-grow: 1;
    background-color: ${(props) => props.theme.danger_select};
    border: none;
    padding: 16px 40px;
    font-size: 20px;
    line-height: 23px;
    margin-right: -25px;
    padding-left: 54px;
    border-radius: 28px;
  }
  input:focus,
  input:active,
  input:visited {
    outline: none;
  }
  input::placeholder {
    font-size: 18px;
    color: #666;
  }

  ${StyledButtonWide} {
    width: 25% !important;
    background-color: ${theme.neonYellow};
  }

  @media (max-width: 768px) {
    img.search-icon {
      left: 35px;
      width: 16px;
    }
    input {
      background-color: ${(props) => props.theme.white};
      width: 100%;
      font-size: 16px;
      margin-right: 0;
      padding-left: 55px;
    }

    input::placeholder {
      font-size: 16px;
    }
  }
`;

const StyledAwardsTableContainer = styled.section`
  margin-bottom: -88px;
  margin-top: 60px;
  position: relative;
  & > img,
  & > svg {
    position: absolute;
  }
  & > img {
    top: -25%;
    right: 0;
    z-index: 1;
  }
  & > svg {
    top: -30%;
    right: 100px;
    z-index: 0;
    animation: ${spin} 5s linear forwards infinite;
  }
  & > div.awards-tabs-container {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    gap: 4px;

    button {
      width: 100%;
      display: inline-block;
    }
  }
  & > div.awards-academy-dropdown-container {
    display: none;
    position: relative;
    z-index: 2;
  }
  & > div.awards-results-container {
    width: 100%;
    padding: 64px 12px 24px 12px;
    background-color: rgb(128, 0, 59);
    height: 53vh;
    position: relative;
    z-index: 9;
    ul {
      width: 100%;
      height: calc(53vh - 88px);
      overflow-y: scroll;
      padding: 0px 12px 0px 52px;
      position: relative;
      li {
        width: 100%;
        padding: 12px 4px;
        border-bottom: 1px solid ${(props) => props.theme.lightBlack};
        &.active {
          position: sticky;
          bottom: 0;
          top: 0;
          background-color: ${(props) => props.theme.neonYellow};
          border-bottom: none;
          p {
            color: ${(props) => props.theme.forestGreen};
          }
        }
        p {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          justify-content: space-between;
          color: ${(props) => props.theme.white};
          .token {
            flex-basis: 13%;
            text-transform: uppercase;
            user-select: all;
            padding-left: 18px;
          }
          .add-padding {
            padding-left: 8px;
          }
          .score {
            text-align: center;
            text-transform: uppercase;
          }
          .time {
            text-align: right;
            padding-right: 18px;
            text-transform: uppercase;
          }
          .rank {
            flex-basis: 11%;
          }
          .award {
            flex-basis: 20%;
            text-align: right;
          }
        }
      }
    }
  }
  & > ${Caption12} {
    margin: 4px 0;
  }

  @media (max-width: 768px) {
    & > img,
    & > svg,
    div.awards-tabs-container {
      display: block;
    }
    & > div.awards-academy-dropdown-container {
      display: none;
    }
    & > div.awards-results-container {
      ul {
        padding: 0px 12px 0px 12px;
        li {
          p {
            .rank {
              flex-basis: 3%;
            }
          }
          ${Text12} {
            font-size: 10px;
          }
          ${Text16} {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
const StyledStructureCompetitionSection = styled.section`
  padding-top: 80px;

  @media (max-width: 768px) {
    padding-top: 40px;
  }
  @media (max-width: 550px) {
    padding-top: 0;
  }
`;
const BlogsContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${(props) => props.theme.warmBeige};

  ${Header25} {
    width: 60%;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    ${Header25} {
      width: 90%;
      font-size: 20px;
    }
  }
`;

//main component
const Awards = (props) => {
  const { t } = useTranslation();
  const searchBarRef = useRef();
  let params = useParams();
  let navigate = useNavigate();
  const isLaptop = useMediaCustom("(min-width: 992px)");
  const { academies, term, checkDatesForDisplayingAwards } =
    useContext(GlobalContext);
  const resetErrors = {
    message: "",
  };

  //initial searchResultErrors object
  const resetSearchResultErrors = {
    message: "",
  };

  //store fetched results by academy in arrays inside the object state
  const [allResults, setAllResults] = useState({});
  const [errors, setErrors] = useState(resetErrors);
  const [showCongrats, setShowCongrats] = useState(false);
  const [showError, setShowError] = useState(false);
  const [academiesForSelectInput, setAcademiesForSelectInput] = useState([]);

  //user token search bar value
  const [userToken, setUserToken] = useState("");
  //keep user result info such as token and academy_id used to successfully navigate back to appointed academy and display result
  const [userSearchResult, setUserSearchResult] = useState(null);
  //store errors returned from fetching token search results
  const [searchResultErrors, setSearchResultErrors] = useState(
    resetSearchResultErrors
  );

  useEffect(() => {
    API.axios
      .get(
        API.createApiRoute("resultsByAcademy", { ACADEMY_ID: params.academyId })
      )
      .then((response) => {
        setAllResults({
          ...allResults,
          [params.academyId]: response.data.data,
        });
      })
      //DON'T FORGET TO HANDLE THIS ERROR
      .catch((error) => {
        if (error.response.status === 404) {
          setErrors({ ...errors, message: t("errors.not_found") });
        }
        if (error.response.status === 500) {
          setErrors({ ...errors, message: t("errors.error_occured") });
        }
      });
  }, [params.academyId]);

  useEffect(() => {
    if (
      term.results_date &&
      !checkDatesForDisplayingAwards(
        { resultsDate: new Date(term.results_date) },
        new Date()
      )
    ) {
      //We show the results all the time now.
      // navigate(API.createRoute("awardsPending"));
    } else if (!params.academyId) {
      //if user lands on domain/awards (without academy id), redirect user to domain/awards/{first academy}

      if (academies.length > 0) {
        navigate(API.createRoute("awards", { ACADEMY_ID: academies[0].id }));
      }
      //} else if (!allResults[params.academyId] && term.results_date && !checkDatesForDisplayingAwards({ resutlsDate: new Date(term.results_date) }, new Date())) {//when academyId changes in URL, check if results for that academy is not already stored in allResults hook
      //we show tht results all the time now
    } else if (!allResults[params.academyId]) {
      //if not, fetch them from backend and store them in allResults hook for later use

      API.axios
        .get(
          API.createApiRoute("resultsByAcademy", {
            ACADEMY_ID: params.academyId,
          })
        )
        .then((response) => {
          setAllResults({
            ...allResults,
            [params.academyId]: response.data.data,
          });
        })
        //DON'T FORGET TO HANDLE THIS ERROR
        .catch((error) => {
          if (error.response.status === 404) {
            setErrors({ ...errors, message: t("errors.not_found") });
          }
          if (error.response.status === 500) {
            setErrors({ ...errors, message: t("errors.error_occured") });
          }
        });
    }

    if (params.academyId && academies.length > 0) {
      let dataForSelectInput = academies.map((academy) => ({
        value: academy.id,
        label: academy.name.replace("Академија за ", ""),
      }));
      setAcademiesForSelectInput(dataForSelectInput);
    }

    //if token and academy id are in the URL, store them in userSearchResults
    //for later sucessfull navigating and displaying search result in current session
    //store token to userToken thus setting the token search bar value to it
    if (params.token && params.academyId) {
      setUserSearchResult({
        academy_id: params.academyId,
        token: params.token,
      });
      setUserToken(params.token.toUpperCase());
    }

    //eslint-disable-next-line
  }, [params.academyId, params.token, academies, term.results_date]);

  //if user lands on url that contains params.academyId that can't be found in database, redirect user to first academy tab
  useEffect(() => {
    if (academies.length > 0 && errors.message === t("errors.not_found")) {
      setErrors({ ...errors, message: "" });
      navigate(API.createRoute("awards", { ACADEMY_ID: academies[0].id }));
    }
    //eslint-disable-next-line
  }, [errors.message, academies, t, navigate]);

  const searchUserResults = (token) => {
    if (token !== "") {
      //send token to backend
      API.axios
        .get(API.createApiRoute("resultsByToken", { TOKEN: token }))
        .then((response) => {
          //if response returns success
          if (response.data.success) {
            //navigate to domain/awards/ACADEMY_ID(from response)/token(from user input)
            //thus securing remounting of application, re-rendering results for the appropriate academy and highlighting user's result
            navigate(
              API.createRoute("awardsWithToken", {
                ACADEMY_ID: response.data.data.academy_id,
                TOKEN: token.toLowerCase(),
              })
            );
            //set response in userSearchResult and add token to it
            setUserSearchResult({ ...response.data.data, token: token });
            if (searchResultErrors.message) {
              //remove errors
              setSearchResultErrors(resetSearchResultErrors);
            }
          } else {
            //if response returns success false, setErrors with appropriate message (token not found)
            setSearchResultErrors({
              ...searchResultErrors,
              message: t("awards.check_rank.errors.token_not_found"),
            });
          }
        })
        .catch((error) => console.error(error.response));
    }
  };

  const submitOnEnter = useCallback(
    (e) => {
      //it triggers by pressing the enter key
      if (e.keyCode === 13) {
        searchUserResults(userToken);
      }
      //eslint-disable-next-line
    },
    [searchResultErrors, userToken]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitOnEnter);

    return () => {
      window.removeEventListener("keydown", submitOnEnter);
    };
  }, [submitOnEnter]);

  useEffect(() => {
    if (userToken !== "" && (userToken.length < 6 || userToken.length > 6)) {
      setSearchResultErrors({
        ...searchResultErrors,
        message: "Токенот мора да има 6 карактери!",
      });
    }
    console.log(allResults[1]);
  }, [userToken]);

  const onInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    setUserToken(value);

    // Only check if the input length is exactly 6
    if (value.length === 6) {
      const foundResult = allResults[1]?.find((result) => {
        const token = result?.token ? String(result.token).toUpperCase() : "";
        return token === value;
      });

      if (foundResult && foundResult) {
        setShowCongrats(true);
        setTimeout(() => setShowCongrats(false), 4000);
        setSearchResultErrors({
          ...searchResultErrors,
          message: "",
        });
      } else {
        setShowError(true);
        setTimeout(() => setShowError(false), 6000);
        setSearchResultErrors({
          ...searchResultErrors,
          message: "",
        });
      }
    } else {
      // Clear the message if the input length is not 6
      setSearchResultErrors({
        ...searchResultErrors,
        message: "",
      });
    }
  };

  return (
    <>
      <MainWrapper>
        {showCongrats && <Confetti />}
        <StyledAwardsMain>
          <Wrapper>
            <div className="logo-wrap">
              <div className="logo-container">
                <UltimathLogo />
              </div>
            </div>

            <StyledHeader>
              <Text33 fontWeight={700}>
                Квалификувани ученици од првата рунда на Ultimath
              </Text33>
              <Text16>
                Резултатите се ажурираат во реално време и листата не е конечна.{" "}
              </Text16>
              <Text16>
                Квалификуваните кандидати ќе бидат објавувани како што ги
                потврдуваме, до затворање на листата во петок.
              </Text16>
            </StyledHeader>

            {/* Search token */}
            {/* <StyledAwardsSearchSection>
              <div className="check-rank-container">
                <SearchBarContainer className="search-bar-container">
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="search-icon"
                  />

                  <input
                    ref={searchBarRef}
                    type="text"
                    onChange={onInputChange}
                    spellCheck={false}
                    value={userToken}
                    placeholder={
                      isLaptop
                        ? t("awards.check_rank.header")
                        : t("awards.check_rank.header_mobile")
                    }
                  />
                </SearchBarContainer>
                {/* Error Message * /}
                <Caption12 color={props.theme.textError}>
                  {searchResultErrors.message}
                </Caption12>
                {/* Congrats Message * /}
                {showCongrats && (
                  <ApproveMessage>
                    <p>Честитки! 🎉</p>
                    <p>Продолжуваш во следната фаза!</p>
                  </ApproveMessage>
                )}
                {/* Reject Message * /}
                {showError && (
                  <RejectMessage
                    captionOne="За жал, не го помина првиот круг на натпреварот."
                    captionTwo="Te охрабруваме да аплицираш повторно следната година! 🚀"
                  />
                )}
              </div>
            </StyledAwardsSearchSection> */}

            {/* Leaderboard Table */}
            <StyledAwardsTableContainer>
              <Caption12 color={props.theme.textError}>
                {errors.message}
              </Caption12>
              {/* <div className="awards-tabs-container">
                {academies.length > 0 &&
                  academies.map((academy) => (
                    // <button key={academy.id}>{academy.name.replace("Академија за ", "")}</button>
                    <AcademyTab
                      highlight={
                        parseFloat(academy.id) === parseFloat(params.academyId)
                      }
                      onClick={() =>
                        userSearchResult?.academy_id &&
                        parseFloat(userSearchResult.academy_id) ===
                          parseFloat(academy.id)
                          ? navigate(
                              API.createRoute("awardsWithToken", {
                                ACADEMY_ID: userSearchResult.academy_id,
                                TOKEN: userSearchResult.token,
                              })
                            )
                          : navigate(
                              API.createRoute("awards", {
                                ACADEMY_ID: academy.id,
                              })
                            )
                      }
                      key={academy.id + academy.name}
                    >
                      <Caption12>
                        {academy.name.replace("Академија за ", "")}
                      </Caption12>
                    </AcademyTab>
                  ))}
              </div> */}

              <div className="awards-academy-dropdown-container">
                <Text12>{t("awards.dropdown.header")}</Text12>
                <SelectInput
                  awards={true}
                  data={academiesForSelectInput}
                  selectedOption={
                    academiesForSelectInput[
                      academiesForSelectInput?.filter(
                        (academy) =>
                          parseFloat(academy.value) ===
                          parseFloat(params.academyId)
                      )[0]?.value - 1
                    ]
                  }
                  onChange={(newValue) =>
                    userSearchResult?.academy_id &&
                    parseFloat(userSearchResult.academy_id) ===
                      parseFloat(newValue.value)
                      ? navigate(
                          API.createRoute("awardsWithToken", {
                            ACADEMY_ID: userSearchResult.academy_id,
                            TOKEN: userSearchResult.token,
                          })
                        )
                      : navigate(
                          API.createRoute("awards", {
                            ACADEMY_ID: newValue.value,
                          })
                        )
                  }
                />
              </div>

              <div className="awards-results-container">
                <ul>
                  <li>
                    <Text12>
                      <span className="token">
                        {t("awards.results_table.header.token")}
                      </span>
                      <span className="score">
                        {t("awards.results_table.header.highschool")}
                      </span>
                      <span className="time">
                        {t("awards.results_table.header.phone")}
                      </span>
                    </Text12>
                  </li>
                  {allResults[params.academyId]?.length > 0 &&
                    allResults[params.academyId].map((applicant, index) => {
                      //if the current applicant token is the search result token return li element with class of active
                      //in other case return li element with onClick function to remove user search bar value
                      if (
                        userToken &&
                        applicant.token.toUpperCase() ===
                          userToken.toUpperCase()
                      ) {
                        return (
                          <li key={index} className="active">
                            <Text16>
                              <span className="token">{applicant.token}</span>
                              <span className="score add-padding">
                                {applicant.highschool}
                              </span>
                              <span className="time add-padding">
                                {applicant.masked_phone}
                              </span>
                              {/* <span className="rank add-padding">
                                {index + 1}
                              </span> */}
                            </Text16>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={index}
                            className=""
                            onClick={() => setUserToken("")}
                          >
                            <Text16>
                              <span className="token">{applicant.token}</span>
                              <span className="score add-padding">
                                {applicant.highschool}
                              </span>
                              <span className="time add-padding">
                                {applicant.masked_phone}
                              </span>
                            </Text16>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            </StyledAwardsTableContainer>
          </Wrapper>
        </StyledAwardsMain>
      </MainWrapper>
      <StyledStructureCompetitionSection>
        <StructureCompetitionSection heading="што е следно?" showRound1And2 />
      </StyledStructureCompetitionSection>
      <BlogsContainer>
        <Header25 color={props.theme.altBlack} fontWeight={600}>
          {t("result.cta.header")}
        </Header25>
        <UltimathBlogs />
      </BlogsContainer>
      <Footer />
    </>
  );
};

export default withTheme(Awards);
