import React, { useEffect, useState } from "react";

const CountdownTimer = ({ startMinutes, fetchData, isTimerActive }) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (!isTimerActive) {
      return;
    }

    // Calculate the remaining time in seconds when the timer becomes active    
    const remainingTimeInSeconds = startMinutes * 60;
    setTimeLeft(remainingTimeInSeconds);

    const timerInterval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 1) {
            clearInterval(timerInterval);
            if (fetchData) {
              fetchData();
            }
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);

    return () => clearInterval(timerInterval);
  }, [isTimerActive, startMinutes]);

  // Format timeLeft as HH:MM:SS
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };
  

  return <span>{formatTime(timeLeft)}</span>;
};

export default CountdownTimer;
