import React from "react";
import styled from "styled-components/macro";

const StyledContainer = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #4caf50;
  opacity: 0;
  transform: scale(0.5);
  animation: scaleUp 0.8s ease-out forwards, colorChange 2s ease-in-out infinite;
  background: rgba(205, 245, 69, 0.20);
  padding: 16px;
  border-radius: 8px;
  margin-top: 40px;
  
  p:first-child {
    margin-bottom: 6px;
  }

  /* Scale-up animation */
  @keyframes scaleUp {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ApproveMessage = ({ children }) => {
  return <StyledContainer className="congrats-message">{children}</StyledContainer>;
};

export default ApproveMessage;
