import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BoardDisplay from "./BoardDisplay";
import WinnerCelebration from "./WinnerCelebration";
import { ultimath } from "../Homepage/Blocks/Footer/Static/ultimath";

// Styled Components
const Wrapper = styled.section`
  background: #1a1b26;
  color: white;
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  .ultimath-logo {
    margin-top: auto;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.04;
  }
`;

const ParticipantGrid = styled.div`
  padding: 20px;
  display: flex;
  gap: 15px;
  /* max-width: 1800px; */
  margin: 0 auto;
  justify-content: center;
  font-size: 25px;

  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    padding-bottom: 50px;
    
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    display: flex;
    grid-template-columns: none;
    /* max-width: 3500px; */
    gap: 30px;
    margin-top: 50px;
    justify-content: center;

  }
`;

const ParticipantChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) =>
    status === "active" ? "#38b000" : "#b0b0b0"};
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 50%;

  &.active {
    background: linear-gradient(135deg, #cdf545, #005840);
    box-shadow: 0 0 15px rgba(5, 150, 105, 0.3);
  }

  &.failed {
    background: linear-gradient(135deg, #eb8484, #c23d3d);
    box-shadow: 0 0 15px rgba(220, 38, 38, 0.3);
    opacity: 0.6;
    text-decoration: line-through;
  }
  &.wildcard {
    background: linear-gradient(135deg, #76d4b0, #1d3007);
    animation: pulseWildcard 3s linear infinite ease-in-out;
  }
  &.wildcard::before {
    content: "👑";
    position: absolute;
    top: -15px; /* Adjust the position to fit the size of the crown */
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px; /* Adjust size of the crown */
    animation: spinY 6s linear infinite;
  }

  &::after {
    content: attr(data-status);
    position: absolute;
    bottom: 120%; /* Adjust to place tooltip above the element */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }

  @keyframes spinY {
    0% {
      transform: translateX(-50%) rotateY(0deg);
    }
    100% {
      transform: translateX(-50%) rotateY(360deg);
    }
  }

  @keyframes pulseWildcard {
    0%,
    100% {
      transform: scale(1);
      background-color: #6c63ff;
    }
    50% {
      transform: scale(1.3);
      background-color: #4e47c4;
    }
  }

  @media (min-width: 2559px) {
    /* Styles for Quad HD screens */
    width: 100px;
    height: 100px;
    font-size: 32px;
    &.wildcard::before {
      content: "👑";
      top: -30px;
      font-size: 50px;
    }
  }
`;

const GOOGLE_SHEET_ID = "1eRjBm5ucgAO_SnqWAnttDSRf_gDUuHStuKPqDsgqrRI";
const GOOGLE_API_KEY = "AIzaSyBSwSm6chaqQoOIy0iQ_EQDzPzzuM12Mw4";
const GOOGLE_SHEETS_ENDPOINT = `https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEET_ID}/values/Sheet1?key=${GOOGLE_API_KEY}`;

export default function Results() {
  const [data, setData] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [eliminatedParticipants, setEliminatedParticipants] = useState([]);
  const [wildcardParticipants, setWildcardParticipants] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [winner, setWinner] = useState(null);
  const [currentRoundPhase, setCurrentRoundPhase] = useState("");
  const [counts, setCounts] = useState({
    active: 0,
    eliminated: 0,
    wildcard: 0,
  });

  const durationMinutes = data?.duration?.[0];
  const durationMilliseconds = durationMinutes * 60 * 1000;
  const isCompetitionFinished = counts?.eliminated === 349

  const getCurrentRoundPhase = (phase) => {
    if (phase === "testSolving") {
      return setCurrentRoundPhase("решавање на тест");
    }
    if (phase === "testChecking") {
      return setCurrentRoundPhase("прегледување на тест");
    }
    if (phase === "resultsDisplaying") {
      return setCurrentRoundPhase("прикажување на резултати");
    }
  };

  const fetchData = async () => {

    try {
      const response = await axios.get(GOOGLE_SHEETS_ENDPOINT);

      const rows = response.data.values;
      const transformedData = rows.reduce((acc, row) => {
        const key = row[0]; // First item is the key
        const values = row.slice(1); // Remaining items are the values
        acc[key] = values;
        return acc;
      }, {});

      setData(transformedData);

    } catch (error) {
      console.error("Error fetching Google Sheets data:", error);

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.start) {
      // const formattedStartTime = new Date(data?.start?.[0]?.replace(" ", "T"));
      let startTimeString = data?.start?.[0]; // "2024-11-21 9:29:00"

      // Add leading zero to the time if needed
      startTimeString = startTimeString.replace(
        /(\d{4}-\d{2}-\d{2}) (\d{1,2}):(\d{2}):(\d{2})/,
        (_, date, hour, minute, second) => {
          return `${date}T${hour.padStart(2, "0")}:${minute.padStart(
            2,
            "0"
          )}:${second.padStart(2, "0")}`;
        }
      );

      const formattedStartTime = new Date(startTimeString);
      setStartTime(formattedStartTime);
    }

    if (data?.phase?.[0]) {
      getCurrentRoundPhase(data?.phase?.[0]);
    }
    let active = [];
    let eliminated = [];
    let wildcard = [];

    if (data.all_participants) {
      setAllParticipants(data.all_participants);
    }

    if (data.round_1_eliminated) {
      data.round_1_eliminated.forEach((item) => {
        eliminated.push(item);
      });
    }
    if (data.round_1_wildcard) {
      data.round_1_wildcard.forEach((item) => {
        wildcard.push(item);
      });
    }
    if (data.round_2_eliminated) {
      let index;
      data.round_2_eliminated.forEach((item) => {
        index = wildcard.indexOf(item);
        if (index > -1) {
          // only splice array when item is found
          wildcard.splice(index, 1); // 2nd parameter means remove one item only
        }

        eliminated.push(item);
      });
    }
    if (data.round_2_wildcard) {
      data.round_2_wildcard.forEach((item) => {
        if (wildcard.indexOf(item) == -1) {
          wildcard.push(item);
        }
      });
    }
    if (data.round_3_eliminated) {
      let index;
      data.round_3_eliminated.forEach((item) => {
        index = wildcard.indexOf(item);
        if (index > -1) {
          // only splice array when item is found
          wildcard.splice(index, 1); // 2nd parameter means remove one item only
        }

        eliminated.push(item);
      });
    }
    if (data.round_3_wildcard) {
      data.round_3_wildcard.forEach((item) => {
        if (wildcard.indexOf(item) == -1) {
          wildcard.push(item);
        }
      });
    }
    if (data.round_4_eliminated) {
      let index;
      data.round_4_eliminated.forEach((item) => {
        index = wildcard.indexOf(item);
        if (index > -1) {
          // only splice array when item is found
          wildcard.splice(index, 1); // 2nd parameter means remove one item only
        }

        eliminated.push(item);
      });
    }
    if (data.round_4_wildcard) {
      data.round_4_wildcard.forEach((item) => {
        if (wildcard.indexOf(item) == -1) {
          wildcard.push(item);
        }
      });
    }
    if (wildcard.length > 0) {
      wildcard.forEach((item) => active.push(item));
    }

    setEliminatedParticipants(eliminated);
    setWildcardParticipants(wildcard);
  }, [data]);

  useEffect(() => {
    let activeCount = 0;
    let eliminatedCount = 0;
    let wildcardCount = 0;

    allParticipants.forEach((participant) => {
      if (eliminatedParticipants.includes(participant)) {
        if (wildcardParticipants.includes(participant)) {
          wildcardCount++;
        } else {
          eliminatedCount++;
        }
      } else {
        activeCount++;
      }
    });

    setCounts({
      active: activeCount,
      eliminated: eliminatedCount,
      wildcard: wildcardCount,
    });
  }, [allParticipants, eliminatedParticipants, wildcardParticipants]);

  useEffect(() => {
    if (counts.eliminated === 349) {
      let newWinner;

      allParticipants.forEach((participant) => {
        if (!eliminatedParticipants.includes(participant)) {
          newWinner = participant;
        } else if (wildcardParticipants.includes(participant)) {
          newWinner = participant;
        }
      });

      setWinner(newWinner);
    }
  }, [counts]);

  return (
    <Wrapper>
      <BoardDisplay
        round={data?.round?.[0]}
        fetchData={fetchData}
        allParticipants={allParticipants.length && allParticipants.length}
        totalActiveParticipants={counts.active + counts.wildcard}
        totalEliminatedParticipants={counts.eliminated}
        durationMilliseconds={durationMilliseconds}
        startTime={startTime}
        phase={currentRoundPhase}
        hasWinner={isCompetitionFinished && winner}
      />

      {!isCompetitionFinished ? (
        <ParticipantGrid>
          {allParticipants.map((participant, index) => {
            let className = "active";
            let status = "Активен";

            if (eliminatedParticipants.includes(participant)) {
              // If participant is eliminated but also in wildcard, give wildcard class
              if (wildcardParticipants.includes(participant)) {
                className = "wildcard";
                status = "Вратен во игра";
              } else {
                className = "failed";
                status = "Елиминиран";
              }
            }
            return (
              <ParticipantChip
                key={index}
                className={className}
                data-status={status}
              >
                {participant}
              </ParticipantChip>
            );
          })}
        </ParticipantGrid>
      ) : (
        <WinnerCelebration winnerNumber={winner} />
      )}

      <div className="ultimath-logo">{ultimath}</div>
    </Wrapper>
  );
}
