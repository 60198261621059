// ExplodingConfetti.js
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Confetti from 'react-dom-confetti';

const fireworkAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

const FireworkWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 20px;
`;

const AnimatedFirework = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  animation: ${fireworkAnimation} 1s ease-out forwards;
`;

const ExplodingConfetti = () => {
  const [isExploding, setIsExploding] = useState(false);

  const confettiConfig = {
    angle: 100,
    spread: 200,
    startVelocity: 45,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#ff6b6b", "#f9c74f", "#6bc1ff", "#9b5de5", "#28c76f"],
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsExploding(true);
      setTimeout(() => setIsExploding(false), 2000); // Reset explosion state after 1 second  
    }, 1000); // Trigger confetti every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <FireworkWrapper>
      <Confetti active={isExploding} config={confettiConfig} />
      {isExploding && <AnimatedFirework />}
    </FireworkWrapper>
  );
};

export default ExplodingConfetti;
